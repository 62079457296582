@import "../../variables";
.features-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 70px 0;
	background-color: #231f28;
	// background-image: url(../../images/bg1.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	> .features-container {
		max-width: $appmaxwidth;
		width: 90%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		> h2 {
			font-weight: 700;
			font-size: 36px;
			line-height: 41px;
			text-transform: uppercase;

			color: #ffffff;
		}
		> div {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			text-align: center;
			justify-content: center;
			align-items: center;
			align-items: center;
			> div {
				width: 290px;
				height: 375px;
				background-color: rgba(62, 49, 79, 0.325);
				border-radius: 10px;
				padding: 15px;
				margin: 20px;
				> div {
					width: calc(100% - 30px);
					height: calc(100% - 30px);
					background: linear-gradient(
						180deg,
						rgba(25, 22, 29, 0),
						rgba(62, 49, 79, 0.325)
					);
					background-repeat: no-repeat, no-repeat;
					background-position: center, no-repeat;
					background-size: contain, 50%;
					cursor: pointer;
					display: flex;
					flex-direction: column;
					align-items: center;
					border-radius: 10px;
					padding: 15px;
					h5 {
						font-weight: 400;
						font-size: 20px;
						text-align: center;
						line-height: 23px;
						color: #ffffff;
					}
					p {
						font-weight: 400;
						text-align: center;
						font-size: 14px;
						line-height: 20px;
						color: #ffffff;
					}
				}
				> div:hover {
					// background-image: url(../../images/fbg2.svg);
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.features-wrapper {
		> .features-container {
			display: flex;
			flex-direction: column;
		}
	}
}
