@import "../../variables";
.footer-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #231f28;
	border-top: 1px solid #00ffa2;
	.logo {
		padding-top: 20px;
		padding-left: 20px;
		width: 180px;
	}
	.footer-content {
		max-width: $appmaxwidth;
		width: calc(100% - 40px);
		padding: 20px;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
		}

		a {
			color: white;
			text-decoration: none;
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 808px) {
	.footer-wrapper {
		.footer-content {
			display: flex;
			flex-direction: column;
		}
	}
}

@media screen and (max-width: 400px) {
	.footer-wrapper {
		.footer-content {
			> div {
				display: flex;
				flex-direction: column;
				text-align: center;
				a {
					margin-bottom: 10px;
				}
			}
		}
	}
}
