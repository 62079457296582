@import "../../variables";
.navbar-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	top: 0px;
	z-index: 500;
	height: 150px;
	color: #fff;
	> .navbar-container {
		width: 80%;
		max-width: $appmaxwidth;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: 0 40px;
		position: relative;
		.logo {
			width: 180px;
		}
		> div {
			display: flex;
			align-items: center;
			> a {
				color: #fff;
				padding: 0 15px;
				text-decoration: none;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				> img {
					// width: 150px;
				}
			}
			> a:hover {
				// transform: scale(1.2);
				color: #00fa9f;
			}
			button {
				color: #010e09 !important;
				border-radius: 50px !important;
				background: #30d69c;
				font-weight: 400 !important;
				font-size: 16px;
				display: inline-block;
				transition: 0.3s;
				margin-right: 0.5em;
				cursor: pointer;
				transition: all 0.3s;
				padding: 13px 28px;
				border: none;
				outline: 0;
				> a {
					font-size: 16px;
					color: #010e09;
					text-decoration: none;
					font-weight: 600;
				}
			}
			button:hover {
				background-color: #08aa6f;
				color: #ffffff;
			}
		}
	}
}
.navbar-wrapper.active {
	display: none;
}

@media screen and (max-width: 1099px) {
	.navbar-wrapper {
		display: none;
	}
}
