.tos {
  top: 100px;
  position: relative;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  > div {
    width: 80%;
    h4 {
      font-size: 28px;
    }
    > p {
      font-size: 14px;
    }
    a {
      text-decoration: none;
      color: #dbd3c5;
    }
  }
}
