@import "../../variables";
.dashboard-wrapper {
	display: flex;
	width: 100%;
	background-color: #231f28;
	height: 100%;
	align-items: center;
	justify-content: center;

	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	.dashboard-container {
		max-width: $appmaxwidth;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		// align-items: center;
		padding: 80px 50px;
		color: white;
		.right {
			width: 60%;

			h3 {
				font-weight: 700;
				font-size: 36px;
				line-height: 53px;
				text-transform: uppercase;
			}
			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 30px;
				margin-right: 50px;
			}
			button {
				background: #30d69c;
				border: 1px solid #30d69c;
				outline: 0;
				color: #0f110f;
				padding: 15px 40px;
				border-radius: 50px;
				cursor: pointer;
				margin-top: 40px;
			}
			button:hover {
				background-color: #08aa6f;
				color: #ffffff;
			}
		}
		.left {
			width: 40%;
			padding-right: 20px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			> img {
				width: 100%;
			}
		}
	}
}
@media screen and (max-width: 1000px) {
	.dashboard-wrapper {
		.dashboard-container {
			display: flex;
			flex-direction: column;
			.left {
				width: 100%;
				p {
					margin-right: 0;
				}
			}
			.right {
				width: 100%;
				margin-top: 50px;
				img {
					width: 70%;
				}
			}
		}
	}
}
