@import "../../variables";
.plans-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	background-color: #19161d;
	justify-items: center;
	padding: 50px 0;
	.plans-container {
		max-width: $appmaxwidth;
		width: 75%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;

		> h4 {
			font-style: normal;
			font-weight: 700;
			font-size: 36px;
			line-height: 41px;
			text-transform: uppercase;
		}

		.tabs-container {
			margin: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			> button {
				text-align: center;
				font-size: 16px;
				padding: 10px 25px;
				color: #fff;
				border-top: none;
				border-left: none;
				border-right: none;
				box-shadow: none;
				border-bottom: 2px solid #021f15;
				outline: none;
				cursor: pointer;
				font-weight: 500;

				background-color: transparent;
			}
			a {
				text-decoration: none;
				color: #fff;
				width: 100%;
			}
			> .selected {
				color: #fff;

				border-bottom: 2px solid #00fa9f;

				color: #00fa9f;
			}
		}
		> .content {
			display: grid;
			margin: 0 auto;
			grid-template-columns: 1fr;
		}
		@media screen and (max-width: 1200px) {
			> .content {
				grid-template-columns: 1fr 1fr;
			}
		}
		@media screen and (max-width: 760px) {
			> .content {
				grid-template-columns: 1fr;
			}
		}
	}
}
