@import "../../variables";
.faq-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #19161d;
	width: 100%;
	align-items: center;
	.faq-container {
		max-width: $appmaxwidth;
		width: 90%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 0;
		padding-bottom: 50px;
		> h5 {
			margin-bottom: 10px;
			color: #fff;
			font-weight: 700;
			line-height: 1.2;
			font-size: 32px;
		}
		> h2 {
			margin-top: 0;
			margin-bottom: 0.5rem;
			font-weight: 500;
			line-height: 1.2;
			font-size: 14px;
			color: #fff;
		}
		.faqcard-wrapper {
			cursor: pointer;
			color: #0f110f;
			background-color: #34d09a;
			border-radius: 7px;
			padding: 25px;
			display: flex;
			justify-content: center;
			text-align: left;
			margin: 20px 0;
			width: 60%;
			> .selector-container {
				width: 10%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				> img {
					width: 12px;
					margin-top: 8px;
					margin-right: 2px;
				}
				.arrow {
					width: 20px;
					color: #0f110f;
				}

				.clicked {
					transform: rotate(180deg);
				}
			}
			> .faqs {
				width: 90%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				> div {
					display: flex;
					align-items: center;
					> .icon {
						color: #09fda5;
						margin-right: 10px;
						height: 30px;
						width: 25px;
					}
				}
				h6 {
					font-size: 14px;
					margin: 0;
					font-weight: 700;
				}
				h6:hover {
					color: white;
				}
				> p {
					font-size: 14px;
					margin: 0;
					margin-top: 10px;
					font-weight: 600;
				}
			}
		}
	}
	a {
		color: white;
		text-decoration: none;
	}
}
