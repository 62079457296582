@import "../../variables";
.home-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: #19161d;
	align-items: center;
	justify-content: center;
	// background-color: #00000000;
	> .home-container {
		max-width: $appmaxwidth;
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		img {
			height: 80px;
		}
		h1 {
			font-family: "Helvetica";
			font-style: normal;
			font-weight: 700;
			margin-top: -65px;
			margin-bottom: 0;
			font-size: 76px;
			text-transform: uppercase;
			color: #30d69c;
			text-shadow: 0 0 10px #32f3af63, 0 0 20px #37fcb748, 0 0 30px #32ecab44,
				0 0 40px #30d69c79, 0 0 50px #30d69c23, 0 0 60px #30d69c49,
				0 0 70px #30d69c42;
		}
		h2 {
			font-family: "Helvetica";
			font-style: normal;
			font-weight: 400;
			font-size: 25px;
			text-transform: uppercase;
			color: #00fa9f;
			padding: 0 20px;
		}
		p {
			font-family: "Helvetica";
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 32px;
			text-align: center;
			color: #ffffff;
			padding: 0 20px;
		}
		a {
			margin-top: 40px;
		}
		button {
			background: #30d69c;
			border: 1px solid #30d69c;
			outline: 0;
			color: #0f110f;
			padding: 15px 40px;
			transition: all 0.3s;
			cursor: pointer;
			border-radius: 50px;
		}
		button:hover {
			background-color: #08aa6f;
			color: #ffffff;
		}
	}
}
@media screen and (max-width: 796px) {
	.home-wrapper {
		> .home-container {
			img {
				width: 80%;
			}
			h1 {
				font-size: 50px;
				padding: 0 20px;
			}
			h2 {
				font-size: 18px;
			}
			p {
				font-size: 14px;
				line-height: 20px;
			}
			a {
				margin-top: 20px;
			}
		}
	}
}

@-webkit-keyframes glow {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
			0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
	}
	to {
		text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
			0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
	}
}
